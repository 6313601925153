import { Options } from "vue-class-component";
import BaseTableMixins from "@/mixins/BaseTable";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import SelectLevel from "@/modules/select-level/select-level.vue";
import ProjectModelTableItem from "@/entity/project/ProjectModelTableItem";
import ModelTree from "./model-tree/model-tree.vue";
import { AttributeScopeOptions, AttributeTypeOptions, ModelObjectOptions, ModelTypeOptions, ProjectEditData } from "@/entity/project/ProjectControl";
import API from "@/api/project/project-model";
import ProjectModelAttributeItem from "@/entity/project/ProjectModelAttributeItem";

@Options({
    name: "app-project-model",
    components: {
        "app-select-level": SelectLevel,
        "app-model-tree": ModelTree,
    },
})
export default class ProjectModel extends BaseTableMixins<ProjectModelTableItem> implements Partial<BaseTableInterface<ProjectModelTableItem>> {
    private currentNode: ProjectModelTableItem = null;
    private topNode: ProjectModelTableItem = new ProjectModelTableItem();
    private projectEditData: ProjectEditData = new ProjectEditData();
    private editAttributeSortTitle = "";
    private editAttributeModelId = 0;
    private editAttributeSortList: any = [];

    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new ProjectModelTableItem();
        this.editModalItem = new ProjectModelTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            modelParentId: {
                type: "eq",
                name: "model_parent_id",
                title: "上级模型",
                parent: null,
                value: -1,
                //openFn: this.modelParentIdLevelModalOpen,
            },
        };
    }

    //请求字段
    public requestItem(): any {
        return {
            page: null,
            pageSize: null,
        };
    }

    public activated(): void {
        this.getList(null);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "设备模型状态",
            message: "设备模型: " + this.editModalItem.modelName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.modelStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.modelStatus = this.editModalItem.modelStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.modelId)) return "设备模型ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //列表回调
    public listCallback(items: Array<ProjectModelTableItem>): void {
        if (!this.currentNode || this.tableRequestItem.search || this.tableRequestItem.lockItem.modelParentId.value == -1) {
            this.tableItems = items;
        } else {
            this.currentNode.children = items;
        }
    }

    //属性类型选项
    get attributeTypeOptions() {
        return AttributeTypeOptions;
    }
    //模型对象选项
    get modelObjectOptions() {
        return ModelObjectOptions;
    }
    //属性作用域选项
    private attributeScopeOptions: any[] = [];
    //模型类型选项
    private modelTypeOptions: any[] = [];
    //设置模型类型添加选项
    public setModelTypeAddOptions(node: ProjectModelTableItem) {
        if (node && node.modelType) {
            if (node.modelType == "class") {
                this.modelTypeOptions = ModelTypeOptions.filter((option: any) => option.value !== "part");
            } else if (node.modelType == "device") {
                this.modelTypeOptions = ModelTypeOptions.filter((option: any) => option.value === "part");
            } else if (node.modelType == "part") {
                this.modelTypeOptions = [];
            } else {
                this.modelTypeOptions = [];
            }
        } else {
            this.modelTypeOptions = [];
        }
    }
    //设置模型类型修改选项
    public setModelTypeModOptions(node: ProjectModelTableItem) {
        if (node && node.modelType) {
            if (node.modelType == "class") {
                this.modelTypeOptions = ModelTypeOptions.filter((option: any) => option.value !== "part");
            } else if (node.modelType == "device") {
                this.modelTypeOptions = ModelTypeOptions.filter((option: any) => option.value !== "part");
            } else if (node.modelType == "part") {
                this.modelTypeOptions = ModelTypeOptions.filter((option: any) => option.value === "part");
            } else {
                this.modelTypeOptions = [];
            }
        } else {
            this.modelTypeOptions = [];
        }
    }
    //设置属性作用域编辑选项
    public setModelScopeEditOptions(node: ProjectModelTableItem) {
        if (node && node.modelType) {
            if (node.modelType == "class") {
                this.attributeScopeOptions = AttributeScopeOptions.filter((option: any) => option.value !== "");
            } else if (node.modelType == "device") {
                this.attributeScopeOptions = AttributeScopeOptions.filter((option: any) => option.value !== "");
            } else if (node.modelType == "part") {
                this.attributeScopeOptions = AttributeScopeOptions.filter((option: any) => option.value === "pre");
            } else {
                this.attributeScopeOptions = [];
            }
        } else {
            this.attributeScopeOptions = [];
        }
    }
    //模型窗口标题
    get modelModalTitle() {
        switch (this.projectEditData.action) {
            case "AddModel":
                return "添加模型";
            case "ModModel":
                return "修改模型";
            default:
                return "未知";
        }
    }
    //属性窗口标题
    get attributeModalTitle() {
        switch (this.projectEditData.action) {
            case "AddAttribute":
                return "添加属性";
            case "ModAttribute":
                return "修改属性";
            default:
                return "未知";
        }
    }
    //动作事件
    public async actionEvent(action: string, node: ProjectModelTableItem, index: number, event: any) {
        this.projectEditData = new ProjectEditData();
        this.projectEditData.action = action;
        this.currentNode = node;
        const modelTypeName = ModelTypeOptions.find((item: any) => item.value === node.modelType)?.label;
        let projectClass = "";
        if (node.modelParentName.length > 0) projectClass = node.modelParentName;
        if (action !== "ModModel") projectClass += `-${node.modelName}`;
        this.projectEditData.projectClass = `(${modelTypeName}) ${projectClass}`;
        switch (action) {
            case "ToggleModelStatus":
                this.tableRequestItem.lockItem.modelParentId.value = node.modelParentId;
                this.currentNode = this.$tools.getPreModelNode(this.tableItems, node);
                this.projectEditData.data = {
                    modelId: node.modelId,
                    modelName: node.modelName,
                    modelStatus: node.modelStatus,
                };
                await this.toggleStatus(this.projectEditData.data);
                break;
            case "SortModel":
                this.tableRequestItem.lockItem.modelParentId.value = node.modelParentId;
                this.currentNode = this.$tools.getPreModelNode(this.tableItems, node);
                await this.sortListView(node);
                break;
            case "ExpandModel":
                this.tableRequestItem.lockItem.modelParentId.value = node.modelId;
                await this.getList(null);
                break;
            case "AddModel":
                this.projectEditData.data = {
                    modelParentId: node.modelId,
                    modelStatus: 1,
                };
                this.setModelTypeAddOptions(node);
                (this.$refs.editModal as any).openModal();
                break;
            case "ModModel":
                this.projectEditData.data = {
                    ...node,
                };
                this.setModelTypeModOptions(node);
                this.projectEditData.data.children = null;
                this.projectEditData.data.attributeJson = null;
                (this.$refs.editModal as any).openModal();
                break;
            case "ToggleAttributeStatus":
                this.projectEditData.data = {
                    index: index,
                    modelId: node.modelId,
                    modelName: node.modelName,
                    ...node.attributeJson[index],
                };
                await this.toggleAttributeStatus(this.projectEditData.data);
                break;
            case "SortAttribute":
                this.editAttributeModelId = node.modelId;
                this.editAttributeSortTitle = "属性排序";
                this.editAttributeSortList = [];
                const attributeJson = node.attributeJson;
                for (let i = 0; i < attributeJson.length; i++) {
                    this.editAttributeSortList.push({
                        id: i,
                        name: "(" + attributeJson[i].code + ")" + attributeJson[i].name,
                        sort: i,
                    });
                }
                (this.$refs.sortAttribute as any).openModal();
                break;
            case "AddAttribute":
                this.projectEditData.data = {
                    modelId: node.modelId,
                    status: 1,
                };
                this.setModelScopeEditOptions(node);
                (this.$refs.editAttribute as any).openModal();
                break;
            case "ModAttribute":
                this.projectEditData.data = {
                    index: index,
                    modelId: node.modelId,
                    ...node.attributeJson[index],
                };
                this.setModelScopeEditOptions(node);
                (this.$refs.editAttribute as any).openModal();
                break;
        }
        return Promise.resolve(true);
    }
    //切换属性状态
    protected async toggleAttributeStatus(item: any) {
        item.status = item.status === 1 ? 2 : 1;
        const statusAlertConfig = {
            title: "设备属性状态",
            message: "设备: " + item.modelName + "<br>属性: " + item.name + "<br>请确认修改状态？",
            closeButtonName: "取消",
            submitButtonName: item.status === 1 ? "启用" : "禁用",
        };
        this.$vueAlert(statusAlertConfig)
            .then(async () => {
                this.isLoading = true;
                await this.API.statusAttribute(item)
                    .then((res: any) => {
                        this.toast.success(res.msg);
                        this.currentNode.attributeJson[item.index].status = item.status;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {
                this.isLoading = false;
            });
    }
    //提交模型
    public async submitModel(): Promise<boolean> {
        if (this.projectEditData.data && this.projectEditData.data.modelCode) {
            this.projectEditData.data.modelCode = this.projectEditData.data.modelCode.toUpperCase();
        }
        if (this.projectEditData.action === "AddModel") {
            await this.API.add(this.projectEditData.data).then((res: any) => {
                this.toast.success(res.msg);
                const nowTime = this.$tools.formatTime(new Date());
                if (this.projectEditData.data.modelParentId < 1) {
                    this.tableItems.push({
                        ...this.projectEditData.data,
                        modelId: res.data.modelId,
                        modelParentCode: "",
                        modelParentName: "",
                        modelRemark: this.projectEditData.data.modelRemark,
                        createTime: nowTime,
                        createName: this.authUser.realName,
                        modifyTime: nowTime,
                        modifyName: this.authUser.realName,
                    } as ProjectModelTableItem);
                } else {
                    const modelParentCode = this.$tools.isEmpty(this.currentNode.modelParentCode)
                        ? this.currentNode.modelCode
                        : this.currentNode.modelParentCode + "-" + this.currentNode.modelCode;
                    const modelParentName = this.$tools.isEmpty(this.currentNode.modelParentName)
                        ? this.currentNode.modelName
                        : this.currentNode.modelParentName + "-" + this.currentNode.modelName;
                    if (this.$tools.isEmpty(this.currentNode.children)) this.currentNode.children = [];
                    this.currentNode.children.push({
                        ...this.projectEditData.data,
                        modelId: res.data.modelId,
                        modelParentCode: modelParentCode,
                        modelParentName: modelParentName,
                        modelRemark: this.projectEditData.data.modelRemark,
                        createTime: nowTime,
                        createName: this.authUser.realName,
                        modifyTime: nowTime,
                        modifyName: this.authUser.realName,
                    } as ProjectModelTableItem);
                    this.currentNode.childrenCount = this.currentNode.children.length;
                    this.currentNode.modelExpand = true;
                }
            });
        } else if (this.projectEditData.action == "ModModel") {
            await this.API.mod(this.projectEditData.data).then((res: any) => {
                this.toast.success(res.msg);
                this.currentNode.modelStatus = this.projectEditData.data.modelStatus;
                this.currentNode.modelType = this.projectEditData.data.modelType;
                this.currentNode.modelCode = this.projectEditData.data.modelCode;
                this.currentNode.modelName = this.projectEditData.data.modelName;
                this.currentNode.modelObject = this.projectEditData.data.modelObject;
                this.currentNode.modelRemark = this.projectEditData.data.modelRemark;
            });
        }
        return Promise.resolve(true);
    }
    //提交属性
    public async submitAttribute(): Promise<boolean> {
        if (this.projectEditData.data && this.projectEditData.data.code) {
            this.projectEditData.data.code = this.projectEditData.data.code.toUpperCase();
        }
        const modelId = this.projectEditData.data.modelId;
        const index = this.projectEditData.data.index;
        const attributeJson: ProjectModelAttributeItem = {
            status: this.projectEditData.data.status || 1,
            scope: this.projectEditData.data.scope,
            type: this.projectEditData.data.type,
            code: this.projectEditData.data.code,
            name: this.projectEditData.data.name,
        };
        if (this.projectEditData.action === "AddAttribute") {
            await this.API.addAttribute({ modelId, attributeJson }).then((res: any) => {
                this.toast.success(res.msg);
                this.currentNode.attributeExpand = true;
                this.currentNode.attributeJson.push(attributeJson);
            });
        } else if (this.projectEditData.action == "ModAttribute") {
            await this.API.modAttribute({ modelId, index, attributeJson }).then((res: any) => {
                this.toast.success(res.msg);
                this.currentNode.attributeExpand = true;
                this.currentNode.attributeJson[index] = attributeJson;
            });
        }
        return Promise.resolve(true);
    }
    //属性排序功能开始
    protected async tableAttributeSortSubmit(): Promise<boolean> {
        if (this.editAttributeSortList < 1) {
            this.toast.error("排序数据不能为空！");
            return Promise.reject(false);
        }
        await this.API.sortAttributeSave({ modelId: this.editAttributeModelId, sortList: this.editAttributeSortList }).then((res: any) => {
            this.toast.success(res.msg);
            const newJson: ProjectModelAttributeItem[] = [];
            for (let i = 0; i < this.editAttributeSortList.length; i++) {
                const index = this.editAttributeSortList[i].id;
                newJson.push(this.currentNode.attributeJson[index]);
            }
            this.currentNode.attributeJson = newJson;
        });
        return Promise.resolve(true);
    }
}
